// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:export {
  primaryColor: #007796;
  errorColor: #d9001b;
  warningColor: #f59a23;
  successColor: #63a103;
  backgroundColor: #272524;
}

.loading-label-container {
  position: absolute;
  top: calc(50% + 1rem);
  text-align: center;
  line-height: 1.5rem;
}
.loading-label-container button.refresh-button {
  all: unset;
  color: #02a4ff;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/colors.module.scss","webpack://./src/components/Elements/loadingElement.scss"],"names":[],"mappings":"AA2BA;EACE,qBA5Bc;EA6Bd,mBA5BY;EA6BZ,qBA5Bc;EA6Bd,qBA5Bc;EA6Bd,wBA5BiB;ACEnB;;AALA;EACE,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AAQF;AAPE;EACE,UAAA;EACA,cDeS;ECdT,eAAA;AASJ","sourcesContent":["$primary-color: #007796;\n$error-color: #d9001b;\n$warning-color: #f59a23;\n$success-color: #63a103;\n$background-color: #272524;\n$background-color-opacity: rgba(29, 26, 25, 0.85);\n$button-contained-background-color: #00538a;\n$button-contained-text-color: #fff;\n$button-contained-border-color: #00538a;\n\n$button-outlined-background-color: #363433;\n$button-outlined-text-color: #fff;\n$button-outlined-border-color: #363433;\n\n$icon-color: #919191;\n$svg-color: #cdcccc;\n$badge-color: #e04545;\n\n$input-background-color: #1d1a19;\n$table-odd-row-background-color: #1d1a19;\n\n$text-color: #d7d6d6;\n$text-color-light: #282929;\n$link-color: #02a4ff;\n$border-color: #1d1a19;\n\n\n:export {\n  primaryColor: $primary-color;\n  errorColor: $error-color;\n  warningColor: $warning-color;\n  successColor: $success-color;\n  backgroundColor: $background-color;\n}","@use \"../../styles/colors.module.scss\" as *;\n.loading-label-container {\n  position: absolute;\n  top: calc(50% + 1rem);\n  text-align: center;\n  line-height: 1.5rem;\n  button.refresh-button {\n    all: unset;\n    color: $link-color;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from "react"

import { Radio, RadioProps } from "@mui/material"

import "./radioElement.scss"

export const RadioElement = ({
  ...props
}: RadioProps) => {
  return <Radio
    {...props}
  />
}
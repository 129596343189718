// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:export {
  primaryColor: #007796;
  errorColor: #d9001b;
  warningColor: #f59a23;
  successColor: #63a103;
  backgroundColor: #272524;
}

label.MuiFormControlLabel-root .MuiTextField-root input {
  background-color: #1d1a19;
  border-radius: 0.25rem;
  font-size: 12px;
  padding: 0.5rem;
}
label.MuiFormControlLabel-root .MuiTextField-root .MuiOutlinedInput-root:not(.Mui-focused) fieldset {
  border-color: #1d1a19;
}`, "",{"version":3,"sources":["webpack://./src/styles/colors.module.scss","webpack://./src/components/Elements/inputElement.scss"],"names":[],"mappings":"AA2BA;EACE,qBA5Bc;EA6Bd,mBA5BY;EA6BZ,qBA5Bc;EA6Bd,qBA5Bc;EA6Bd,wBA5BiB;ACEnB;;AAFI;EACE,yBDamB;ECZnB,sBAAA;EACA,eAAA;EACA,eAAA;AAKN;AAFM;EACE,qBDMiB;ACFzB","sourcesContent":["$primary-color: #007796;\n$error-color: #d9001b;\n$warning-color: #f59a23;\n$success-color: #63a103;\n$background-color: #272524;\n$background-color-opacity: rgba(29, 26, 25, 0.85);\n$button-contained-background-color: #00538a;\n$button-contained-text-color: #fff;\n$button-contained-border-color: #00538a;\n\n$button-outlined-background-color: #363433;\n$button-outlined-text-color: #fff;\n$button-outlined-border-color: #363433;\n\n$icon-color: #919191;\n$svg-color: #cdcccc;\n$badge-color: #e04545;\n\n$input-background-color: #1d1a19;\n$table-odd-row-background-color: #1d1a19;\n\n$text-color: #d7d6d6;\n$text-color-light: #282929;\n$link-color: #02a4ff;\n$border-color: #1d1a19;\n\n\n:export {\n  primaryColor: $primary-color;\n  errorColor: $error-color;\n  warningColor: $warning-color;\n  successColor: $success-color;\n  backgroundColor: $background-color;\n}","@use \"../../styles/colors.module.scss\" as *;\n\nlabel.MuiFormControlLabel-root {\n  .MuiTextField-root {\n    input {\n      background-color: $input-background-color;\n      border-radius: .25rem;\n      font-size: 12px;\n      padding: .5rem;\n    }\n    .MuiOutlinedInput-root:not(.Mui-focused) {\n      fieldset {\n        border-color: $input-background-color;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

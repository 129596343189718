import React, { CSSProperties, ReactElement, useEffect, useRef } from "react"

import { combineClassNames } from "../util"

import "./panelElement.scss"

export interface PanelElementProps {
  title?: string
  content?: ReactElement | string
  footer?: ReactElement | string
  style?: CSSProperties
  className?: string
  closePanel?: () => void
  /**
   * Ref for the button that toggles the panel.
   * Used to prevent closing when clicking the toggle itself.
   */
  toggleButtonRef?: React.RefObject<Element>
}

export const PanelElement = ({ title, content, footer, style, className, closePanel, toggleButtonRef }: PanelElementProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      event.target instanceof Node &&
      !panelRef.current?.contains(event.target) &&
      !toggleButtonRef?.current?.contains(event.target)
    ) {
      closePanel?.();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return <div
    ref={panelRef}
    className={combineClassNames(["panel box-shadow", className])}
    style={style}>
    {title && (
      <div className="panel-header">{title}</div>
    )}
    {content && (
      <div className="panel-content">
        {content}
      </div>
    )}
    {footer && (
      <>
        <hr />
        <div className="panel-footer">
          {footer}
        </div>
      </>
    )}
  </div>
}
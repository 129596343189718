import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  FieldMergeFunction,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { relayStylePagination } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";

import { apolloAuthContext, getObservableTokenHeader } from "../auth/authHandler";
import { EdgeNodeProps } from "../components/Mapbox";
import { appConfig } from "../config";

/**
 * GraphQl backend can be used for querying:
 * * mosaic
 * * customer details data
 * * power network data
 */
export const graphqlLink = new HttpLink({
  uri: `${appConfig.graphqlUrl}`,
});

/**
 * Adds [Error Link](https://www.apollographql.com/docs/react/api/link/apollo-link-error/) to handle errors
 */
export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (operation.getContext().response?.status === 401) {
    const observableTokenHeader = getObservableTokenHeader(operation, forward)
    return observableTokenHeader;
  }
  if (graphQLErrors) {
    Sentry.captureException(graphQLErrors);
  }
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

// Retry logic with exponential backoff
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (_error, operation: Operation) => {
      return [408, 425, 429, 500, 502, 503, 504].includes(operation.getContext()?.response?.status)
    },
  },
});

// The [Apollo Link](https://www.apollographql.com/docs/react/api/link/introduction) is used to handle multiple endpoints.
export const apolloLinks = ApolloLink.from([
  retryLink,
  errorLink,
  apolloAuthContext.concat(graphqlLink),
]);

const relayPolicy = relayStylePagination([
  // keyArgs param makes sure data is cached based on this key
  "sourceNetworkLevel",
])

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allPowerline: {
          ...relayPolicy,
          merge(existing, incoming, { ...options }) {
            const { args } = options;
            if (args?.uid) {
              /**
               *  When querying specific powerline uids, we want a custom merge function that adds them to the top of the list.\
               *  Keeping the existing cursor and other pageInfo for our pagination to work.
               */
              const existingUids = existing?.edges?.map((edge: { node: EdgeNodeProps }) => edge.node.uid);
              const filteredIncomingPowerlines = incoming?.edges?.filter((edge: { node: EdgeNodeProps }) => {
                return !existingUids?.includes(edge.node.uid)
              })
              return {
                ...existing,
                edges: [...filteredIncomingPowerlines, ...existing.edges],
              }
            }
            return (relayPolicy.merge as FieldMergeFunction)(existing, incoming, options);
          },
        },
        availableMosaic: {
          keyArgs: ["customerOrganization", "snapshot"],
        },
      },
    },
  },
})

const client = new ApolloClient({
  link: apolloLinks,
  cache,
})

export { client }

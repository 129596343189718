import { AccountInfo } from "@azure/msal-browser"

export const combineClassNames = (classNames: (string | undefined | boolean)[]) => {
  return classNames
    .filter(Boolean)
    .join(" ").trim()
}

export const showExperimentalFeatures = (accounts: AccountInfo[]) => {
  return accounts.some(account => account.username.endsWith("@stormgeo.com"))
}
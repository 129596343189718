import { gql } from "@apollo/client";
import { SNAPSHOT_FIELDS, SNAPSHOT_THUMBNAIL_FIELD } from "./fragments";

export const GET_AVAILABLE_MOSAIC = gql`
  query (
    $customerOrganization: String!
    $snapshot: String!
  ) {
    availableMosaic(
      customerOrganization: $customerOrganization
      snapshot: $snapshot
    ) {
      mosaicUid
      mosaicPath
      mosaicType
      mosaicName
      mosaicDescription
    }
  }
`;

export const GET_POWERLINES = gql`
  query ($after: String, $uid: UUID, $pageSize: Int = 10, $sourceNetworkLevel: Int, $organizationId: Decimal) {
  allPowerlines(
    first: $pageSize
    after: $after
    sourceNetworkLevel: $sourceNetworkLevel
    uid: $uid
    organizationUid: $organizationId
  ) {
    edges {
      node {
        ... on PowerlineNode {
          objectType
          uid
          owner
          ownerOrganization
          name
          commissionedYear
          voltageKv
          sourceName
          sourceNetworkLevel
          sourceNetworkLevelName
          sourceCreated
          sourceUpdated
          measurementMethod
          accuracy
          retrievalDate
          exportType
          geometry {
            type
            coordinates
          }
          centroid {
            type
            coordinates
          }
          lengthKm
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`;

export const GET_SUGGESTIONS = gql`
  query GetSuggestions(
    $after: String,
    $pageSize: Int = 10,
    $orderBy: String = "-intersectionsAvgRisk",
    $organizationId: Decimal
    $snapshot: String
    $latestClassification: EnergynetClearingCorridorMetricsLatestClassificationChoices
  ) {
    allClearingCorridorMetrics(
      first: $pageSize
      after: $after
      orderBy: $orderBy
      organizationUid: $organizationId
      snapshotName: $snapshot
      latestClassification: $latestClassification
    ) {
      edges {
        node {
          uid
          intersections(first: 1) {
            edges {
              node {
                treeSpecies
                treeHeightM
                nearestDistanceM
                crownRadiusM
                diameterAtBreastHeightM
                criticalWindSpeedMps
                staticWeatherRiskPct
                intersectingPolygon {
                  type
                  coordinates
                }
              }
            }
          }
          intersectionsCount
          intersectionsMaxRisk
          intersectionsAvgRisk
          latestClassification
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_SUGGESTION = gql`
  query GetSuggestion(
    $clearingcorridormetricsId: ID!
  ) {
    clearingCorridorMetrics(
      id: $clearingcorridormetricsId
    ) {
      uid
      intersections {
        edges {
          node {
            treeSpecies
            treeHeightM
            nearestDistanceM
            crownRadiusM
            diameterAtBreastHeightM
            criticalWindSpeedMps
            staticWeatherRiskPct
            geometry {
              coordinates
              type
            }
          }
        }
      }
      intersectionsCount
      intersectionsMaxRisk
      intersectionsAvgRisk
      latestClassification
    }
  }
`;

export const GET_CORRIDOR_METRICS = gql`
  query GetCorridorMetrics(
    $after: String
    $pageSize: Int = 10
    $orderBy: String = "-intersectionsAvgRisk"
    $organizationId: Decimal
    $snapshot: String
    $latestClassification: EnergynetClearingCorridorMetricsLatestClassificationChoices
    $regionUid: Decimal
  ) {
    allClearingCorridorMetrics(
      first: $pageSize
      after: $after
      orderBy: $orderBy
      organizationUid: $organizationId
      snapshotName: $snapshot
      latestClassification: $latestClassification
      regionUid: $regionUid
    ) {
      edges {
        node {
          uid
          clearingcorridor {
            id
            uid
            powerline {
              sourceUid
              sourceName
              voltageKv
              region {
                name
              }
            }
            areaKm2
            lineLengthKm
            geometry {
              coordinates
              type
            }
          }
          intersectionsCount
          intersectionsMaxRisk
          intersectionsAvgRisk
          latestClassification
          snapshot {
            uid
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_REPORT_LIST = gql`
  query AllReports(
    $pageSize: Int = 10
    $organizationUid: Decimal
  ) {
    allReports(
      first: $pageSize
      organizationUid: $organizationUid
    ) {
      edges {
        node {
          name
          createdTimestamp
          status
          updatedTimestamp
          organization {
            uid
            name
          }
          reportclearingcorridorSet {
            edges {
              node {
                clearingcorridor {
                  areaKm2
                  uid
                }
              }
            }
          }
          id
          uid
        }
      }
    }
  }
`;

export const GET_REPORT = gql`
  query GetReport($reportId: ID!) {
    report(id: $reportId) {
      name
      snapshot {
        name
      }
      reportclearingcorridorSet {
        edges {
          node {
            clearingcorridor {
              uid
              powerline {
                voltageKv
              }
              geometry {
                coordinates
              }
              lineLengthKm
              areaKm2
            }
          }
        }
      }
      createdTimestamp
    }
  }
`;

export const GET_MASTS = gql`
  query {
    allMast {
      edges {
        node {
          objectType
          uid
          owner
          ownerOrganization
          name
          commissionedYear
          measurementMethod
          accuracy
          retrievalDate
          exportType
          geometry {
            type
            coordinates
          }
          id
          sourceNetworkLevel
          sourceNetworkLevelName
          sourceCreated
          sourceUpdated
        }
      }
    }
  }
`;

export const GET_TRANSLATION = gql`
  query(
    $language: String!
    $killcache: String
  ) {
    translations (
      language: $language
      killcache: $killcache
    ) {
      translations
    }
  }
`;

export const GET_SNAPSHOTS = gql`
  query GetSnapshots($organizationId: Decimal) {
    allSnapshots (
      organizationUid: $organizationId
    ) {
      edges {
        node {
          id
          __typename
          ...SnapshotFields
        }
      }
    }
  }
  ${SNAPSHOT_FIELDS}
`;

export const GET_SNAPSHOTS_WITH_THUMBNAIL = gql`
  query GetSnapshotsWithThumbnail($organizationId: Decimal) {
    allSnapshots (
      organizationUid: $organizationId
    ) {
      edges {
        node {
          id
          __typename
          ...SnapshotFields
          ...SnapshotThumbnailField
        }
      }
    }
  }
  ${SNAPSHOT_FIELDS}
  ${SNAPSHOT_THUMBNAIL_FIELD}
`;

export const GET_SAR_ORDERS = gql`
  query (
    $pageSize: Int = 10
    $organizationId: Decimal
    ) {
    allSarTaskingOrders (
      first: $pageSize
      organizationUid: $organizationId
    ) {
      edges {
        node {
          createdTimestamp
          id
          name
        }
      }
    }
  }
`;

export const GET_CORRIDORS = gql`
  query {
    allClearingcorridor {
      edges {
        node {
          uid
          powerlineSegmentUid
          geometry {
            type
            coordinates
          }
          geometryHash
          lineLengthKm
          areaKm2
          crsEpsg
          imageCoverKm2
          createdTimestamp
          updatedTimestamp
          metrics {
            edges {
              node {
                intersections {
                  edges {
                    node {
                      uid
                      geometry {
                        type
                        coordinates
                      }
                      intersectingPolygon {
                        type
                        coordinates
                      }
                      treeAreaM2
                      nearestDistanceM
                      stacItemId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query {
    allOrganizations {
      edges {
        node {
          uid
          name
        }
      }
    }
  }
`;

export const GET_MAP_FEED = gql`
  query {
    mapFeed {
      maxZoom
      steps {
        timestep
        url
      }
      subdomains
      tileSize
    }
  }
`;

export const GET_REGIONS = gql`
  query AllRegions($organizationId: Decimal) {
    allRegions(organizationUid: $organizationId) {
      edges {
        node {
          uid
          name
        }
      }
    }
  }
`;

export const GET_LAYER_GROUPS = gql`
  query AllLayerGroups($organizationId: Decimal) {
    allLayerGroups(organizationUid: $organizationId) {
      edges {
        node {
          layers {
            edges {
              node {
                type {
                  uid
                  translationKey
                  translationParams
                  indicatorColor
                  checked
                  beforeId
                  popupFields
                  legend
                  sourceProps
                  layerProps
                }
                order
              }
            }
          }
          type {
            translationKey
          }
        }
      }
    }
  }
`;
